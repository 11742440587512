import styled from "@emotion/styled";
import { Descriptions, Empty, Modal, Spin } from "antd";
import { useExpressModal } from "../util";

export const ExpressModal = () => {
  const { recordModalOpen, editingDeliver, close, isLoading } =
    useExpressModal();
  const closeModal = () => {
    close();
  };

  return (
    <Modal
      title={"物流轨迹"}
      width={800}
      visible={recordModalOpen}
      onCancel={closeModal}
      footer={null}
    >
      {isLoading ? (
        <Loading>
          <Spin size={"large"} />
        </Loading>
      ) : (
        <>
          {editingDeliver?.extra_data.length ? (
            <Descriptions size={"small"} column={1}>
              {editingDeliver?.extra_data.map((item, index) => (
                <Descriptions.Item key={index}>
                  {item.datetime}-
                  {item.remark}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </>
      )}
    </Modal>
  );
};

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
