import { QueryKey, useMutation, useQuery } from "react-query";
import { useHttp } from "./http";
import { cleanObject } from "utils/index";
import {
  useAddAddressConfig,
  useDeleteConfig,
  useEditAddressConfig,
} from "./use-optimistic-options";

import type {
  ShopAddressForm,
  ShopAddressListResult,
  ShopAddressListSearchParams,
} from "types/shopAddress";

export const useShopAddressList = (
  params: Partial<ShopAddressListSearchParams>
) => {
  const client = useHttp();
  return useQuery<ShopAddressListResult>(["address_list", params], () => {
    const { page, per_page, ...restParams } = params;
    return client("/api/v1/admin/address/mapping/shop/index", {
      data: cleanObject({
        "filter[province_id]": restParams.province_code,
        "filter[city_id]": restParams.city_code,
        "filter[area_id]": restParams.area_code,
        "filter[supplier_id]": restParams.supplier_id,
        page,
        per_page,
      }),
    });
  });
};

export const useAddShopAddress = (queryKey: QueryKey) => {
  const client = useHttp();
  return useMutation(
    (params: Partial<ShopAddressForm>) =>
      client("/api/v1/admin/address/mapping/store", {
        data: cleanObject({ ...params }),
        method: "POST",
      }),
    useAddAddressConfig(queryKey)
  );
};

export const useEditShopAddress = (queryKey: QueryKey) => {
  const client = useHttp();
  return useMutation(
    ({ id, ...params }: ShopAddressForm) =>
      client(`/api/v1/admin/address/mapping/${id}`, {
        data: params,
        method: "POST",
      }),
    useEditAddressConfig(queryKey)
  );
};

export const useDeleteShopAddress = (queryKey: QueryKey) => {
  const client = useHttp();
  return useMutation(
    (id: string) =>
      client(`/api/v1/admin/address/mapping/delete/${id}`, {
        method: "POST",
      }),
    useDeleteConfig(queryKey)
  );
};
